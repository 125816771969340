.light-accent {
  background-color: #AD9F88;
}
.main-brand-color {
  background-color: #A9383F;
}

.dark-accent {
  background-color: #8095A1;
}

.dark-shades {
  color: #292933;
}

.header {
  font-size: 8vh;
  text-align: center;
}